@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Px_Grotesk_Pan-Bold";
  src:
    url("./fonts/PxGroteskPan/PxGroteskPan-Bold.woff") format("woff"),
    url("./fonts/PxGroteskPan/PxGroteskPan-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Px_Grotesk_Pan-Regular";
  src:
    url("./fonts/PxGroteskPan/PxGroteskPan-Regular.woff") format("woff"),
    url("./fonts/PxGroteskPan/PxGroteskPan-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Px_Grotesk-Light";
  src:
    url("./fonts/PxGrotesk/Px-Grotesk-Light.woff") format("woff"),
    url("./fonts/PxGrotesk/Px-Grotesk-Light.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Questrial";
  src:
    url("./fonts/Questrial/Questrial-Regular.woff") format("woff"),
    url("./fonts/Questrial/Questrial-Regular.woff2") format("woff2");
  font-weight: 400;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
}

body,
html {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

body {
  margin: 0 auto;
  padding: 10px, 0, 50px, 0;
  font-family:
    "Px_Grotesk_Pan-Regular",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  outline: none;
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
  text-decoration: none;
}

.social-icon path {
  fill: var(--color);
}
.social-icon:hover path {
  fill: var(--hover-color);
  transition: fill 0.5s;
}

@keyframes borderAnimation {
  0% {
    border-color: white;
  }
  50% {
    border-color: #00f58c;
  }
  100% {
    border-color: #b8fbcf;
  }
}

.animated-border {
  animation: borderAnimation 4s infinite ease-in-out;
}
